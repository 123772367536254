<template>
  <v-container fluid style="height: calc(100vh - 48px); position:relative;">
    <mx-preloader :loader-status="preloader"/>
    <v-row class="mt-4 mb-7">
      <v-col class="d-flex">
        <v-btn
            style="padding: 24px 0 25px 0;"
            class="mr-7"
            elevation="2"
            color="primary"
            medium
            :to="{name: 'roles'}"
        >
          <v-icon size="32">mdi-chevron-left</v-icon>
        </v-btn>
<!--        <h1>{{ $t(`middleware.role.form.update`) }}</h1>-->
        <h1>
<!--          {{ $t(`middleware.role.role`) }} -->
          {{ form.display_name }}
        </h1>
        <div class="ml-auto">
          <v-btn
              style="padding-top: 24px; padding-bottom: 24px;"
              elevation="2"
              color="green"
              dark
              medium
              @click="save()"
              v-if="mxware.can('role-update')"
          >
            <v-icon size="28">mdi-content-save-outline</v-icon>
          </v-btn>
          <v-btn
              style="padding-top: 24px; padding-bottom: 24px;"
              class="ml-1"
              elevation="2"
              color="red"
              dark
              medium
              @click="dialog = true"
              v-if="mxware.can('role-delete')"
          >
            <v-icon size="28">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-6">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                  outlined
                  hide-details
                  disabled
                  label="Name"
                  placeholder="Name"
                  v-model="form.name"
                  :error="errors.name!==undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                  outlined
                  hide-details
                  label="Display name"
                  placeholder="Display name"
                  v-model="form.display_name"
                  :error="errors.display_name!==undefined"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                  outlined
                  hide-details
                  label="Description"
                  placeholder="Description"
                  v-model="form.description"
                  :error="errors.description!==undefined"
              ></v-text-field>
            </v-col>
          </v-row>
<!--          {{ form.permissionSelected }}-->
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-6">
          <v-text-field
              outlined
              hide-details
              label="Suche"
              placeholder="Suche..."
              v-model="search"
          ></v-text-field>
          <v-row class="mt-4">
            <v-col cols="12" md="4" lg="3" v-for="(permission, name, index) in getPer" :key="index">
              <v-card class="mx-card-role pa-3 pb-6" style="height: 100%">
                <v-card-title>{{ ucFirst(name) }}</v-card-title>
                <v-checkbox
                    v-for="(item, index) in permission"
                    :key="index"
                    :input-value="checkin(item.id)"
                    class="ml-3"
                    :label="item.display_name"
                    hide-details
                    @click="checken(item.id)"
                ></v-checkbox>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          persistent
          max-width="500"
      >
        <v-card class="pa-4">
          <v-card-title class="headline mb-9">
            <h3 style="text-align: center; word-break: break-word;">
              {{ $t('middleware.role.delete.title') }}
            </h3>
          </v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="red" dark @click="roleDelete">
              {{ $t('middleware.buttons.delete') }}
            </v-btn>
            <v-btn color="grey" dark @click="dialog = false">
              {{ $t('middleware.buttons.abort') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      preloader: true,
      dialog: false,
      permissions: [],
      search: '',
      form: {
        id: '',
        name: '',
        display_name: '',
        description: '',
        permissionSelected: []
      },
      errors: []
    }
  },
  mounted() {
    this.$store.dispatch('roleEditLaratrust', this.$route.params.id).then(response => {
      this.form.id = response.data.role.id
      this.form.name = response.data.role.name
      this.form.display_name = response.data.role.display_name
      this.form.description = response.data.role.description
      this.form.permissionSelected = this.getPermissionSelectedIds(response.data.role.permissions)

      this.permissions = response.data.permissions
      this.preloader = false
    })
  },
  computed: {
    getPer() {
      return Object.keys(this.permissions).filter(key => {
        return key.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      }).reduce((obj, key) => {
        obj[key] = this.permissions[key];
        return obj;
      }, {});
    }
  },
  methods: {
    getPermissionSelectedIds(permissions) {
      let res = []
      permissions.map(role => res.push(role.id))
      return res
    },
    checken(id) {
      let index = this.form.permissionSelected.indexOf(id)

      if (index !== -1) {
        this.form.permissionSelected.splice(index, 1)
      } else {
        this.form.permissionSelected.push(id)
      }
    },
    checkin(id) {
      return this.form.permissionSelected.indexOf(id) !== -1
    },
    save() {
      this.itemLowerCase()
      this.$store.dispatch('roleUpdateLaratrust', this.form).then(() => {
        this.$router.replace({
          name: 'roles'
        })
      }).catch(error => {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            this.btn_create_loading = false
            break;
        }
      })
    },
    roleDelete() {
      this.$store.dispatch('roleDeleteLaratrust', this.form.id).then(() => {
        this.$router.replace({
          name: 'roles'
        })
      })
    },
    ucFirst(str) {
      if (!str) return str;
      return str[0].toUpperCase() + str.slice(1);
    },
    itemLowerCase() {
      this.form.name = this.form.name.toLowerCase()
    }
  }
}
</script>
